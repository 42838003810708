<template>
	<div class="card">
		<h6>Select Fluids</h6>
		<MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Select Countries" :filter="true">
			<template #value="slotProps">
				<div class="inline-flex align-items-center py-1 px-2  border-round mr-2" v-for="option of slotProps.value" :key="option.code">
					<div>{{option.name}}</div>
				</div>
				<template v-if="!slotProps.value || slotProps.value.length === 0">
					<div class="p-1">Select Fluids</div>
				</template>
			</template>
			<template #option="slotProps">
				<div class="flex align-items-center">
					<div>{{slotProps.option.name}}</div>
				</div>
			</template>
		</MultiSelect>
		<br/>
		<br/>
		<br/>
		<br/>
		<div v-if="fields!=undefined && fields.input!=undefined" class="p-fluid formgrid grid">
           
			<div   v-for="item in fields.input" :key="item" class="field col-12 md:col-4">
				<label for="item.name">{{item.title}}</label>
				<!-- v-on:input="onWeightChange" -->
				<InputNumber v-tooltip.top="item.description"  v-on:blur="fetchresult" v-on:input="onChangeValues($event,item)" v-model="inputrequest[item.name]"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"></InputNumber>
			</div>
		
        </div>
		<br/>
		<br/>
		<div v-if="fields!=undefined && fields.output!=undefined" class="p-fluid formgrid grid">    		

			<div   v-for="item in fields.output" :key="item" class="field col-12 md:col-4">
				<label v-tooltip.bottom="item.description" for="item.name">{{item.title}}</label>
				<!-- v-on:input="onWeightChange" -->
				<InputNumber  style="background:darkgray" :disabled="true" v-model="inputrequest[item.name]"  mode="decimal" :minFractionDigits="2" :maxFractionDigits="5"></InputNumber>
			</div>
		
        </div>
	</div>
	
</template>
<script>
    import CreService from "../../service/CreService";
	export default {
		data() {
			return {
				fields: undefined,
				inputrequest:{},		
				apirequest:{cycle_no:2},	
				multiselectValue:[],
				multiselectValues: [
					{name: '22DIMETHYLBUTANE', code: 'AU'},
					{name: '1BUTYNE', code: 'BR'},
					{name: '3METHYLPENTANE', code: 'CN'}					
				],	
			}
		},
	
       
		created() {			
            this.creService = new CreService();
		},
		mounted() {
            this.creService.getByCycleName(this.$route.params.cycle).then((data) => {
				let rdata =data.data;
				let sdata=JSON.stringify(rdata);
				this.fields = JSON.parse(sdata);
				for (let key in this.fields.input){
					this.inputrequest[this.fields.input[key].name]=0.0;
					this.apirequest[this.fields.input[key].name]=0.0;
				}
				
				this.fields = JSON.parse(sdata);
				console.log(this.inputrequest);
			});
		},
		methods: {

		onChangeValues(e,item){			
			console.log(item.name);
			this.apirequest[item.name] =  e.value;
            console.log(this.inputrequest);
        },
		
		fetchresult(){
			console.log(JSON.stringify(this.apirequest));
			this.creService.save(this.apirequest).then((data) => {	
				let rdata =data.data;
				let sdata=JSON.stringify(rdata);
				let adata = JSON.parse(sdata);			
				for (let key in adata) {
					this.inputrequest[key]=adata[key];
				}
				
				console.log(this.inputrequest);
			});
		}
			
		}
	}
</script>