import BaseService from './BaseService';

export default class CreService extends BaseService{


    constructor(){
        super();
    }


    getByCycleName(name){       
        return this.getAxios().get('/api/cre/cycle/'+name)
    }
    
    save(srate_record){       
        return this.getAxios().post('/api/cre/cycle/Cycle_1', srate_record)
    }

    
}
